<template>
	<div v-if="Mailboxes">
		<v-tabs v-model="tabs" fixed-tabs class="mb-3">
			<v-tabs-slider></v-tabs-slider>
			<v-tab>
				<v-icon>mdi-ticket-account</v-icon>
				Open Tickets
			</v-tab>

			<v-tab>
				<v-icon>mdi-ticket-confirmation</v-icon>
				Closed Tickets
			</v-tab>
		</v-tabs>
		<v-row>
			<v-col xs="12" sm="12" md="4" lg="4" xl="3">
				<blurb>
					<v-row>
						<v-col>
							<h3>Filters</h3>
							<hr />
							<v-text-field
								:append-outer-icon="search ? 'mdi-close' : ''"
								@click:append-outer="search = null"
								class="mt-2"
								v-model="search"
								label="Search For"
							/>

							<v-select
								item-text="text"
								v-model="mailbox"
								:items="Mailboxes"
								label="Mailbox"
								return-object
							/>
							<v-autocomplete
								:append-outer-icon="AsigneeID !== 0 ? 'mdi-close' : ''"
								@click:append-outer="AsigneeID = 0"
								v-model="AsigneeID"
								label="Assignee"
								:items="usersInOrg"
								item-text="Name"
								item-value="id"
							/>
							<v-select
								:append-outer-icon="TypeID !== 0 ? 'mdi-close' : ''"
								@click:append-outer="TypeID = 0"
								v-model="TypeID"
								label="Type"
								:items="types"
								item-text="title"
								item-value="id"
							/>
						</v-col>
					</v-row>
					<!-- {{ $vuetify.breakpoint.name }} -->
				</blurb>
			</v-col>
			<v-col>
				<v-skeleton-loader :loading="!loaded" type="list-item-three-line@5">
					<v-list>
						<v-list-item
							v-for="item in tickets"
							:key="item.ticketid"
							:to="`/ticket/${mailbox.id}/${item.ticketid}`"
						>
							<v-list-item-avatar color="dark" style="color:white;">
								<strong>{{ item.ticketid }}</strong>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>
									<strong>
										<!-- {{
									item.TRANSCRIPTION
										? item.TRANSCRIPTION
										: "No transcription available"
								}} -->
										{{ item.callername }} - {{ item.phone }}
									</strong>
								</v-list-item-title>
								<v-list-item-subtitle>
									<span class="mt-2">{{ item.origdate | diffForHumans }}</span>
									<div class="mt-1">
										<v-chip
											:color="calculateColor(item.status, 'info')"
											:class="`mr-2`"
											small
										>
											{{ item.status ? item.status : "Uncategorized" }}
										</v-chip>
										<v-chip
											:color="calculateColor(item.Type, 'info')"
											class="mr-2"
											small
										>
											{{ item.Asignee ? item.Asignee : "Unassigned" }}
										</v-chip>
										<v-chip
											:color="calculateColor(item.Type, 'secondary')"
											:class="`mr-2`"
											small
										>
											{{ item.Type ? item.Type : "Uncategorized" }}
										</v-chip>

										<br />
									</div>
								</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-skeleton-loader>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import dayjs from "dayjs";
import axios from "axios";
import { mapState } from "vuex";
import Blurb from "@/components/Blurb.vue";
export default {
	data() {
		return {
			mailbox: null,
			AllTickets: null,
			search: "",
			AsigneeID: 0,
			TypeID: 0,
			tabs: 0,
			loading: false,
		};
	},
	mounted() {
		dayjs();
		if (this.Mailboxes) this.mailbox = this.Mailboxes[0];
	},
	components: { Blurb },
	computed: {
		...mapState(["Organization", "Mailboxes", "usersInOrg", "types"]),
		loaded() {
			// eslint-disable-next-line
			return !this.loading;
		},
		tickets() {
			if (!this.search && this.AsigneeID === 0 && this.TypeID === 0)
				return this.AllTickets;
			else {
				let Tickets = this.AllTickets;
				if (this.AsigneeID !== 0) {
					Tickets = Tickets.filter((x) => x.AsigneeID === this.AsigneeID);
				}
				if (this.TypeID !== 0) {
					Tickets = Tickets.filter((x) => x.TypeID === this.TypeID);
				}
				let SearchTerm = this.search ? this.search.toLowerCase() : "";
				return Tickets.filter(
					(x) =>
						x.MailboxTitle?.toLowerCase().includes(SearchTerm) ||
						x.TRANSCRIPTION?.toLowerCase().includes(SearchTerm) ||
						x.callername?.toLowerCase().includes(SearchTerm) ||
						x.phone?.toLowerCase().includes(SearchTerm) ||
						x.Asignee?.toLowerCase().includes(SearchTerm) ||
						x.Type?.toLowerCase().includes(SearchTerm)
				);
			}
		},
	},
	filters: {
		diffForHumans: (date) => {
			if (!date) {
				return null;
			}

			const d = dayjs(date);
			return d.format("dddd, MMMM D") + " at " + d.format("hh:mm A");
		},
	},
	watch: {
		Mailboxes: {
			deep: true,
			handler(v) {
				this.c++;
				console.log("mailboxes");
				if (!this.mailbox) this.mailbox = v[0];
			},
		},
		mailbox() {
			this.updateMailbox();
		},
		tabs(v) {
			this.updateMailbox(v ? "Closed" : "");
		},
	},
	methods: {
		async updateMailbox(filter = "") {
			this.loading = true;
			try {
				let res = await axios.get(
					`https://api.ticketvm.com/organization/${this.Organization.id}/mailbox/${this.mailbox.id}/tickets/${filter}`
				);
				this.AllTickets = res.data;
				this.loading = false;
			} catch (error) {
				if (error.response) console.log(error.response);
				this.loading = false;
			}
		},
		calculateColor(v, defaultColor) {
			switch (v) {
				case null:
				case undefined:
					return "yellow accent-4";
				case "Created":
					return "success";
				default:
					return defaultColor;
			}
		},
	},
};
</script>

<style scoped>
.purple {
	color: white;
}
</style>
